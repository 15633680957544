//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import axios from "axios";
import Popup from "@/components/Popup";

/**
 * Rbac component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
  },
  data() {
    return {
      info: {},
      logCrm: [],
      logBackend: [],
      newBlockIp: {
        ip: '',
        blockMinutes: 0
      },
      logCrmPopup: {
        show: false,
        closeButton: "Закрыть",
      },
      logBackendPopup: {
        show: false,
        closeButton: "Закрыть",
      },
      addBlockIpPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.add'),
        actionClass: "btn-success"
      },
      title: this.$t('menuitems.admin.list.servers'),
      items: [
        {
          text: this.$t('menuitems.admin.text'),
        },
        {
          text: this.$t('menuitems.admin.list.servers'),
          active: true
        }
      ]
    };
  },
  validations: {
    
  },
  computed: {
    
  },
  methods: {
    closeAddBlockPopup(){
      this.addBlockIpPopup.show = false;
    },
    showAddBlockPopup(){
      this.addBlockIpPopup.show = true;
    },
    addBlockIpSubmit(){
      this.$store.dispatch('startPreloader');
      let formData = new FormData();
      formData.append('ip', this.newBlockIp.ip);
      formData.append('minutes', this.newBlockIp.blockMinutes);

      axios
        .post(`/v1/servers/block-ip`, formData)
        .then(() => {
          this.$store.dispatch("addNotification", {
            text: 'IP добавлен в блокировку',
            time: 3,
            color: "success"
          });
          this.closeAddBlockPopup();
          this.newBlockIp.ip = '';
          this.newBlockIp.blockMinutes = '';
          this.getInfo();
          this.$store.dispatch('stopPreloader');
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch('stopPreloader');
        });
    },
    getInfo(){
      axios
          .get(`/v1/servers/info`)
          .then(resp => {
            this.info = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },  
    getLogCrm(){
      this.$store.dispatch('startPreloader');
      axios
        .get(`/v1/servers/log-crm`)
        .then(resp => {
          this.logCrm = resp.data;
          this.logCrmPopup.show = true;
          this.$store.dispatch('stopPreloader');
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch('stopPreloader');
        });
    },
    closeLogCrmPopup(){
      this.logCrmPopup.show = false;
    },
    closeLogBackendPopup(){
      this.logBackendPopup.show = false;
    },
    getLogBackend(){
      this.$store.dispatch('startPreloader');
      axios
        .get(`/v1/servers/log-backend`)
        .then(resp => {
          this.logBackend = resp.data;
          this.logBackendPopup.show = true;
          this.$store.dispatch('stopPreloader');
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch('stopPreloader');
        });
    }
  },
  created() {
    this.getInfo();
  }
};
